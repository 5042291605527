.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.loading-spinner::before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}


.card-margin .card {
  margin-right: 1rem;
  margin-bottom: 1rem;
}


.card-shimmer {
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  animation: cardShimmer 1s infinite;
}

.card-shimmer__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.25) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
}

.card-shimmer__content {
  padding: 1rem;
}

.card-shimmer__title {
  width: 50%;
  height: 1.5rem;
  margin-bottom: 0.5rem;
  background-color: #ccc;
  border-radius: 0.25rem;
  animation: shimmer 2s infinite;
}

.card-shimmer__text {
  width: 80%;
  height: 1rem;
  background-color: #ccc;
  border-radius: 0.25rem;
  /* animation: shimmer 2s infinite; */
}

/* @keyframes cardShimmer {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
} */

.card-margin {
  margin-top: 20px;
}
.card-deck {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.sales-card {
  display: flex;
  border: none;
}

.dropzone{
  border: dotted 2px #444;
  border-radius: 5px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}